'use client'
import { IGif } from '@giphy/js-types'
import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react'
type RelatedGifsProps = {
    relatedGifs: IGif[]
    setRelatedGifs: Dispatch<SetStateAction<IGif[]>>
    currentGif?: IGif
    setCurrentGif: Dispatch<SetStateAction<IGif | undefined>>
}

export const GifsContext = createContext({
    relatedGifs: [],
    setRelatedGifs: () => {},
    setCurrentGif: () => {},
} as RelatedGifsProps)

const RelatedGifsManager = ({ children }: { children: ReactNode }) => {
    const [relatedGifs, setRelatedGifs] = useState<IGif[]>([])
    const [currentGif, setCurrentGif] = useState<IGif>()
    return (
        <GifsContext.Provider value={{ relatedGifs, setRelatedGifs, currentGif, setCurrentGif }}>
            {children}
        </GifsContext.Provider>
    )
}

export default RelatedGifsManager
